<template>
  <div class="container">
    <div class="form-card">
      <div class="card-head">
        <h4 class="card-title">
          <span>Kullanıcı Detay </span>
          <i class="icon icon-arrow-right-3"></i>
          {{ formData.given_name + " " + formData.family_name }}
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <TextTemplate
              label="E-mail"
              :value="formData.email"
              keyName="formData.email"
              type="text"
              :className="v$.formData.email.$errors.length > 0 ? 'error' : ''"
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.formData.email.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Telefon"
              :value="formData.mobile_phone"
              keyName="formData.mobile_phone"
              type="text"
              @onChangeText="handleText"
            />
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Ad"
              :value="formData.given_name"
              keyName="formData.given_name"
              type="text"
              :className="
                v$.formData.given_name.$errors.length > 0 ? 'error' : ''
              "
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.formData.given_name.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Soyad"
              :value="formData.family_name"
              keyName="formData.family_name"
              type="text"
              :className="
                v$.formData.family_name.$errors.length > 0 ? 'error' : ''
              "
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.formData.family_name.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Müşteri</label>
              <Multiselect
                v-model="formData.customer_set"
                :options="customerList"
                label="name"
                track-by="name"
                :multiple="true"
                placeholder=""
                selectLabel=""
                selectedLabel=""
                deselectLabel=""
              ></Multiselect>
            </div>
          </div>
          <div class="col-md-12">
            <button class="btn btn-filled-primary" @click="updateUser">
              Güncelle
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Done
    @closeModal="doneModalClose"
    v-if="isCloseModal"
    :doneMsg="popupText"
    :doneTitle="popupTitle"
    @click="closeModal"
    link="/users"
  />
</template>
<script>
import useValidate from "@vuelidate/core";
import { helpers, required, email } from "@vuelidate/validators";
export default {
  data() {
    return {
      v$: useValidate(),
      isCloseModal: false,
      popupText: "",
      popupTitle: "",
      formData: {
        email: "",
        given_name: "",
        family_name: "",
        mobile_phone: "",
        groups: ["CustomerUser"],
        customer_set: [],
      },
      customerList: [],
      userId: this.$route.params.id,
    };
  },
  validations() {
    return {
      formData: {
        given_name: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        family_name: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        email: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          email: helpers.withMessage(
            "Lütfen geçerli bir e-mail giriniz.",
            email
          ),
          $autoDirty: true,
        },
      },
    };
  },

  created() {
    this.getCustomers();
    this.$axios.get(`/user/${this.userId}/`).then(async (response) => {
      let user = response.data;
      this.formData = {
        email: user.email,
        given_name: user.given_name,
        family_name: user.family_name,
        mobile_phone: user.mobile_phone,
        groups: user.groups,
        customer_set: [],
      };
      const allCustomers = await this.$axios.get("/customer/");
      user.customer_set.forEach((customerId) => {
        let customer = allCustomers.data.find(
          (customer) => customer.id === customerId
        );
        if (customer) {
          this.formData.customer_set.push({
            id: customer.id,
            name: customer.name,
          });
        }
      });
    });
    this.$store.commit("theme/addToBreadcrumps", [
      {
        title: "Anasayfa",
        path: "/",
      },
      {
        title: "Kullanıcılar",
        path: "/users",
      },
      {
        title: "Kullanıcı Detay",
      },
      {
        title: `${this.$route.params.id}`,
      },
    ]);
  },
  methods: {
    doneModalClose(value) {
      this.isCloseModal = value;
    },
    updateUser() {
      const newCustomerSets = [...this.formData.customer_set];
      const postData = { ...this.formData };
      postData.customer_set = newCustomerSets.map((customer) => customer.id);
      this.v$.formData.$touch();
      if (!this.v$.formData.$error) {
        this.$axios
          .put(`/user/${this.$route.params.id}/`, postData)
          .then(() => {
            this.popupText = "Kullanıcı Güncellendi";
            this.popupTitle = "Kullanıcı Güncelleme";
            this.isCloseModal = true;
          });
      }
    },
    getCustomers() {
      this.$axios.get("/customer/").then((response) => {
        response.data.filter((customer) => {
          this.customerList.push({
            id: customer.id,
            name: customer.name,
          });
        });
      });
    },
    handleText(data) {
      let keynames = data.keyName.split(".");
      keynames.length <= 1
        ? (this[keynames[0]] = data.value)
        : (this[keynames[0]][keynames[1]] = data.value);
    },
  },
};
</script>